import React, { useEffect, useState } from "react";

export default function ApplicantInformation() {
    const [showLocationField, setShowLocationField] = useState(false);
    useEffect(() => {
    }, []);

    const onAddLocationClick = () => {
        setShowLocationField(true)
    }
    const onDeleteLocationClick = () => {
        setShowLocationField(false)
    }
    return (
        // <div className="row m-0 chromdivmain">
        //     <div id="main-div" className="col-12 ">
        //         <div className="container-fluid  ">
        //             <div className="row main-page-container-row ">
        //                 <div className="col-3 sidebar-css ">
        //                     <Sidebar />
        //                 </div>
        //                 <div className="col-7 ms-2  position-relative content-col">
        //                     <>
                                <div className="row ">
                                    <div className="col-6 mt-1">
                                        <fieldset>
                                            <legend class="middle-legend">Business Street Address</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Business Street Address"
                                                value="513 Central Ave"
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-6 ">
                                        <fieldset>
                                            <legend class="middle-legend">Suite/Apt.(optional)</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Suite/Apt.(optional)"
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">City</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="City"
                                                value="Jersey City"
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">State</legend>
                                            <select class="form-control" id="dropdownSelect">
                                                <option value="">State</option>
                                                <option value="option1" selected>NJ</option>
                                            </select>
                                        </fieldset>

                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Zip Code</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Zip Code"
                                                value="07307"
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-12  my-2">
                                        <div class="form-check ps-0">
                                            <input type="checkbox" class="form-check-input mt-3" id="check2" name="option2" value="something" checked/>
                                            <label class="form-check-label" for="check2">Mailing address is the same as the Business address.</label>
                                        </div>


                                    </div>
                                    <div className="col-6  mt-1">
                                        <fieldset>
                                            <legend class="middle-legend">Mailing street Address</legend>

                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Mailing street Address"
                                            />

                                        </fieldset>
                                    </div>
                                    <div className="col-6  mt-1">
                                        <fieldset>
                                            <legend class="middle-legend">Suite/Apt.(optional)</legend>

                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Suite/Apt.(optional)"
                                            />

                                        </fieldset>
                                    </div>


                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">City</legend>

                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Enter city"
                                                value="Jersey City"
                                            />

                                        </fieldset>

                                    </div>

                                    <div className="col-4">
                                        <fieldset>

                                            <legend class="middle-legend">State</legend>
                                            <select class="form-control" id="dropdownSelect">
                                                <option value="">State</option>
                                                <option value="option1">NJ</option>
                                            </select>

                                        </fieldset>

                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Zip Code</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Zip Code"
                                                value="07307"
                                            />
                                        </fieldset>
                                    </div>

                                    {/* <div className="col-12 my-2">
                                        <span onClick={onAddLocationClick} className="icon-span">
                                            <i className="fa fa-plus add-icon"></i>
                                            <label>Add locations</label>
                                        </span>
                                    </div> */}
                                    {showLocationField ?
                                        <>
                                            <div className="col-6  mt-1">
                                                <fieldset>
                                                    <legend class="middle-legend">Street Address</legend>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="exampleTextField"
                                                        placeholder="Street Address"
                                                    />
                                                </fieldset>
                                            </div>
                                            <div className="col-5  mt-1">
                                                <fieldset>
                                                    <legend class="middle-legend">Suite/Apt.(optional)</legend>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="exampleTextField"
                                                        placeholder="Suite/Apt.(optional)"
                                                    />

                                                </fieldset>
                                            </div>
                                            <div className="col-1  pt-3">
                                                <span onClick={onDeleteLocationClick} className="icon-span">
                                                    <i className="fa fa-trash delete-icon"></i>
                                                </span>
                                            </div>
                                            <div className="col-4">
                                                <fieldset>
                                                    <legend class="middle-legend">City</legend>

                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="exampleTextField"
                                                        placeholder="City"
                                                    />
                                                </fieldset>
                                            </div>

                                            <div className="col-4">
                                                <fieldset>
                                                    <legend class="middle-legend">State</legend>
                                                    <select class="form-control" id="dropdownSelect">
                                                        <option value="option1">Option 1</option>
                                                        <option value="option2">Option 2</option>
                                                        <option value="option3">Option 3</option>
                                                    </select>
                                                </fieldset>

                                            </div>
                                            <div className="col-4">
                                                <fieldset class="custom-legend">
                                                    <legend class="middle-legend">Zip Code</legend>

                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="exampleTextField"
                                                        placeholder="Zip Code"
                                                    />

                                                </fieldset>
                                            </div>
                                        </>
                                        :
                                        null}
                                </div>
                                // <div className="mb-4">

                                //     <div className="text-end mt-5 next-btn-position">
                                //         <button className="btn btn-lg btn-primary" onClick={onNextClick} >Next</button><br />
                                //         <span>{validationMessage}</span>
                                //     </div>
                                // </div>

            //                 </>

            //             </div>

            //         </div>
            //     </div>
            // </div>

        // </div>
    )
}
