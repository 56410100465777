import React from 'react'
import { Link } from 'react-router-dom';

export default function Sidebar(props) {
 
  return (
    <ul className="nav nav-pills flex-column mb-sm-auto mb-0  " id="menu">
      <li>
        <Link onClick={props.onCoverageBackClick} className={props.applicantInfo?"nav-link active":"nav-link"}> <span className="ms-1 d-none d-sm-inline">Applicant Information</span></Link>
      </li>
      <li>
        <Link to="" className={props.coverageHistoryInfo?"nav-link other-nav-link active":"nav-link other-nav-link"}> <span className="ms-1 d-none d-sm-inline">Coverage History</span></Link>
      </li>
      <li>
        <Link to=""  className={props.lossInfo?"nav-link other-nav-link active":"nav-link other-nav-link"}> <span className="ms-1 d-none d-sm-inline">Loss Information</span></Link>
      </li>
      <li>
        <Link to="" className={props.classCode?"nav-link other-nav-link active":"nav-link other-nav-link"}> <span className="ms-1 d-none d-sm-inline">Class Codes</span></Link>
      </li>

    </ul>
  )
}
