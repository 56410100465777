import React from 'react'
import logo from '../assets/images/YourCompanyLogo.png'
 import {Button} from 'insurancegignpm/dist'
 import { Link } from 'react-router-dom';

export default function About() {

  return (
    <div className="row m-0 chromdivmain">
    <div id="main-div" className="col-12 ">
      <img className="mx-auto header-css" src={logo}  />
      <div className="container">
        <div className="col-12 " style={{textAlign: 'center', position:'absolute', top:'35%'}}>
          <h3 style={{fontSize: '24px'}}><b> Introducing GigMarket - a portal of Apps that is absolutely 100%
              free to access.</b></h3>
    
        <h2 className style={{fontSize: '18px', marginTop: '10px', textAlign: 'center'}}>With each application
          bringing you the
          powers of the insurtech companies out there.</h2>
          
          </div>
       
        <Button  buttonText="InsuranceGIG" className='ig-btn-position'></Button>
      
      </div>
    </div>
  
  </div>
  )
}
