import React, { useEffect, useState } from "react";
import Sidebar from '../Components/Sidebar';
import ApplicantInformation from "./ApplicantInformation";
import CoverageHistory from "./CoverageHistory";
import LossInformation from "./LossInformation";
import ClassCodes from "./ClassCodes";
import {Button} from 'insurancegignpm/dist'

export default function MainPage() {
    const [applicantInfo, setApplicantInfo] = useState(true)
    const [coverageHistoryInfo, setCoverageHistoryInfo] = useState(false)
    const [lossInfo, setLossInfo] = useState(false)
    const [classCode, setClassCode] = useState(false)

    const onApplicantNextClick = () => {
        setApplicantInfo(false)
        setCoverageHistoryInfo(true)
        setLossInfo(false)
        setClassCode(false)
    }
    const onCoverageHistoryNextClick = () => {
        setApplicantInfo(false)
        setCoverageHistoryInfo(false)
        setLossInfo(true)
        setClassCode(false)

    }
    const onCoverageBackClick = () => {
        setCoverageHistoryInfo(false)
        setApplicantInfo(true)
        setLossInfo(false)
        setClassCode(false)
    }
    const onLossInfoBackClick = () => {
        setCoverageHistoryInfo(true)
        setApplicantInfo(false)
        setLossInfo(false)
        setClassCode(false)

    }
    const onLossInfoNextClick = () => {
        setCoverageHistoryInfo(false)
        setApplicantInfo(false)
        setLossInfo(false)
        setClassCode(true)
    }
    const onClassCodeBackClick = () => {
        setCoverageHistoryInfo(false)
        setApplicantInfo(false)
        setLossInfo(true)
        setClassCode(false)
    }

    return (
        <div className="row m-0 chromdivmain">
            <div id="main-div" className="col-12 ">
                <div className="container-fluid  ">
                    <div className="row main-page-container-row ">
                        <div className="col-3 sidebar-css ">
                            <Sidebar onCoverageBackClick={onCoverageBackClick} 
                            classCode={classCode}
                            lossInfo={lossInfo}
                            coverageHistoryInfo={coverageHistoryInfo}
                            applicantInfo={applicantInfo}
                            />
                        </div>
                        <div className="col-7 ms-2  position-relative content-col">
                            <>
                                {applicantInfo ?
                                    <>
                                        <ApplicantInformation />
                                        <div className="mb-4">
                                            <div className="text-end mt-5  back-btn-position">
                                                <button className="btn btn-lg btn-primary" onClick={onApplicantNextClick} >Next</button><br />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {coverageHistoryInfo ?
                                    <>
                                        <CoverageHistory />
                                        <div className="mb-4">
                                            <div className="text-start mt-5 back-btn-position">
                                                <button className="btn btn-lg btn-primary" onClick={onCoverageBackClick} >Back</button>
                                                <button className="btn btn-lg btn-primary  me-2" onClick={onCoverageHistoryNextClick} >Next</button><br />
                                            </div>
                                      
                                        </div>
                                    </>
                                    : null}
                            </>

                            {lossInfo ?
                                <>
                                    <LossInformation />
                                    <div className="mb-4">
                                        <div className="text-start mt-5 back-btn-position">
                                            <button className="btn btn-lg btn-primary me-2" onClick={onLossInfoBackClick} >Back</button>
                                            <button className="btn btn-lg btn-primary" onClick={onLossInfoNextClick} >Next</button><br />
                                        </div>
                                        
                                    </div>
                                </>
                                : null}

                            {classCode ?
                                <>
                                    <ClassCodes />
                                    <div className="mb-4">
                                        <div className="text-end mt-5 back-btn-position">
                                            <button className="btn btn-lg btn-primary me-2 " onClick={onClassCodeBackClick} >Back</button>
                                            {/* <span className="ig-button"> */}
                                            <Button buttonText="Premium Financing" closeButtonText="Cancel" className="ig-button"/>
                                            {/* </span> */}
                                          
                                        </div>
                                      
                                    </div>
                                </>
                                : null}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
