import React, { useEffect, useState } from "react";

export default function CoverageHistory() {
    const today = new Date().toISOString().split('T')[0];
    const [dateInputValue, setDateInputValue] = useState(today);

    useEffect(() => {
    }, []);
    const handleDateInputChange = (event) => {
        setDateInputValue(event.target.value);
    }
  
    return (

        <>
            <div className="row ">
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Contractor License #</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Contractor License "
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Desired Effective Date</legend>
                        <input
                            type="date"
                            className="form-control date-control"
                            id=""
                            placeholder="Desired Effective Date"
                            value={dateInputValue}
                            onChange={handleDateInputChange}
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">FEIN Number</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="FEIN Number"
                            value="542876156"
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Entity Type</legend>
                        <select class="form-control" id="dropdownSelect">
                            <option value="">Select Entity</option>

                            <option value="Association" selected>Association</option>
                            <option value="Common Ownership">Common Ownership</option>
                            <option value="Corporation">Corporation</option>
                            <option value="Government Entity">Government Entity</option>
                            <option value="Individual">Individual</option>
                            <option value="Joint Employers">Joint Employers</option>
                            <option value="Joint Venture">Joint Venture</option>
                            <option value="Labor Union">Labor Union</option>
                            <option value="Limited Liability Company">Limited Liability Company</option>
                            <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                            <option value="Limited Partnership">Limited Partnership</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Religious Organization">Religious Organization</option>
                            <option value="Trust or Estate">Trust or Estate</option>

                        </select>
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Legal Business Name</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Legal Business Name"
                            value="Pizzale"
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">DBA</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="DBA"
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Contact First Name</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Contact First Name"
                            value="David"
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Contact Last Name</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Contact Last Name"
                            value="Lastname"
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Contact Email Address</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Contact Email Address"
                            value="demouser@yourcompanyname.com"
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Contact Phone</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Contact Phone"
                            value="(999)999-9999"
                        />
                    </fieldset>
                </div>
                <div className="col-12 ">
                    <div class="form-check form-switch ps-0 d-flex align-items-center">
                        <label class="form-check-label" for="flexSwitchCheckDefault"> Do you have an experience modifier?</label>
                        <input class="form-check-input h4 " type="checkbox" role="switch" id="flexSwitchCheckDefault" checked />
                    </div>

                </div>
                <div className="col-6 mt-3">
                    <fieldset>
                        <legend class="middle-legend">Limits of Insurance</legend>
                        <select class="form-control" id="dropdownSelect">
                            <option value="">Select Limits</option>
                            <option value="100,000/500,000/100,000" selected>100,000/500,000/100,000</option>
                            <option value="500,000/500,000/500,000">500,000/500,000/500,000</option>
                            <option value="1,000,000/1,000,000/1,000,000">1,000,000/1,000,000/1,000,000</option>

                        </select>
                    </fieldset>
                </div>
                <div className="col-6 mt-3">
                    <fieldset>
                        <legend class="middle-legend">Years of Industry Experience</legend>
                        <select class="form-control" id="dropdownSelect">
                            <option value="">Select years</option>
                            <option value="0">0</option>
                            <option value="1" selected>1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7+</option>
                        </select>
                    </fieldset>
                </div>

                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Year the Business was Established</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Year the Business was Established"
                            value="2018"
                        />
                    </fieldset>
                </div>
                <div className="col-6 mt-1">
                    <fieldset>
                        <legend className="middle-legend">Blanket Waiver of Subrogation</legend>
                        <div className="form-check d-inline mt-3">
                            <input
                                className="form-check-input mt-3"
                                type="radio"
                                name="cityOption"
                                id="radioOption1"
                                value="option1"
                                checked
                            />
                            <label className="form-check-label" htmlFor="radioOption1">
                                No
                            </label>
                        </div>
                        <div className="form-check d-inline mt-3">
                            <input
                                className="form-check-input mt-3"
                                type="radio"
                                name="cityOption"
                                id="radioOption2"
                                value="option2"
                            />
                            <label className="form-check-label" htmlFor="radioOption2">
                                Yes
                            </label>
                        </div>
                    </fieldset>
                </div>

                <div className="col-6 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Website</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Website"
                        />
                    </fieldset>
                </div>
            </div>


        </>


    )
}
