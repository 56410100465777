import React, { useEffect, useState } from "react";

export default function ClassCodes() {
    const [dateInputValue, setDateInputValue] = useState("");

    const handleDateInputChange = (event) => {
        setDateInputValue(event.target.value);
    }

    return (

        <>
            <div className="row ">
                <div className="col-4 ">
                    <fieldset>
                        <legend class="middle-legend">Location </legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Location"
                            value="513 Central Ave, Jersey City, NJ, 07307"
                        />
                    </fieldset>
                </div>
                <div className="col-8 ">
                    <fieldset>
                        <legend class="middle-legend">Class Code </legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Class Code"
                            value="Bank"
                        />
                    </fieldset>
                </div>
                <div className="col-4 ">
                    <fieldset>
                        <legend class="middle-legend">Annual Payroll</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Annual Payroll"
                            value="$ 125,000"
                        />
                    </fieldset>
                </div>
                <div className="col-2 d-flex align-items-top">
                    <label class="mt-3">Employee Count</label>
                </div>
                <div className="col-3 ">
                    <fieldset>
                        <legend class="middle-legend">FT</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="FT"
                            value="500"
                        />
                    </fieldset>
                </div>
                <div className="col-3 ">
                    <fieldset>
                        <legend class="middle-legend">PT</legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="PT"
                            value="0"
                        />
                    </fieldset>
                </div>
                {/* <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Date of Loss</legend>
                                            <input
                                                type="date"
                                                className="form-control date-control"
                                                id=""
                                                placeholder="Date of Loss"
                                                value={dateInputValue}
                                                onChange={handleDateInputChange}
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Description of Loss</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Description of Loss"
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Closed?</legend>
                                            <div class="form-check d-inline mt-3">
                                                <input class="form-check-input mt-3" type="radio" name="cityOption" id="radioOption1" value="option1" />
                                                <label class="form-check-label" for="radioOption1">
                                                    No
                                                </label>
                                            </div>
                                            <div class="form-check d-inline mt-3">
                                                <input class="form-check-input mt-3" type="radio" name="cityOption" id="radioOption2" value="option2" />
                                                <label class="form-check-label" for="radioOption2">
                                                    Yes
                                                </label>
                                            </div>
                                        </fieldset>

                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Reserve Amount</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Reserve Amount"
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Paid Amount</legend>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"
                                                placeholder="Paid Amount"
                                            />
                                        </fieldset>
                                    </div>
                                    <div className="col-4">
                                        <fieldset>
                                            <legend class="middle-legend">Medical Or Indemnity</legend>
                                            <div class="form-check d-inline ">
                                                <input class="form-check-input mt-3 mt-3" type="radio" name="cityOption" id="radioOption1" value="option1" />
                                                <label class="form-check-label " for="radioOption1">
                                                    Medical
                                                </label>
                                            </div>
                                            <div class="form-check d-inline ">
                                                <input class="form-check-input mt-3 mt-3" type="radio" name="cityOption" id="radioOption2" value="option2" />
                                                <label class="form-check-label " for="radioOption2">
                                                    Indemnity
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="col-12">
                                        <fieldset>
                                            <legend class="middle-legend">Describe corrective actions / procedures implemented to prevent future similar claims.</legend>
                                            <textarea
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"

                                            />
                                        </fieldset>
                                    </div> */}
            </div>
        </>
    )
}
