import React, { useEffect, useState } from "react";

export default function LossInformation() {
    const today = new Date().toISOString().split('T')[0];
    const [effectiveInputValue, setEffectiveInputValue] = useState(today);
    const [expirationInputValue, setExpirationInputValue] = useState(today);
    const [priorYear0, setPriorYear0] = useState(false);
    const [priorYear1, setPriorYear1] = useState(false);
    const [priorCoverageYearList, setPriorCoverageYearList] = useState([
        { Name: 0, Value: 0 },
        { Name: 1, Value: 1 }
    ]);
    const handleEffectiveDateInputChange = (event) => {
        setEffectiveInputValue(event.target.value);
    }
    const handleExpirationDateInputChange = (event) => {
        setExpirationInputValue(event.target.value);
    }
    useEffect(() => {

    }, []);
    // const priorCoverageDropdownClicked = (e) => {
    //     const selectedValue = parseInt(e.target.value, 10);
    //     if (selectedValue === 0) {
    //         setPriorYear0(true)
    //         setPriorYear1(false)
    //     }
    //     else if (selectedValue === 1) {
    //         setPriorYear0(false)
    //         setPriorYear1(true)
    //     }
    //     else {
    //         setPriorYear0(false)
    //         setPriorYear1(false)
    //     }
    // }

   
    return (

        <>
            <div className="row ">
                <div className="col-12 mb-3">
                    <div class="form-check form-switch ps-0 d-flex align-items-center">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Has the Applicant ever hired employees? </label>
                        <input class="form-check-input h4" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked />
                    </div>
                </div>
                <div className="col-12 ">
                    <fieldset>
                        <legend class="middle-legend">How many years of prior coverage does the Applicant have?</legend>
                        {/* <select
                                                onChange={priorCoverageDropdownClicked}
                                                class="form-control"
                                              
                                            >
                                                <option className="form-control " selected>
                                                    Select
                                                </option>

                                                {priorCoverageYearList.map((option, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={option.Value}
                                                            className="form-control"
                                                        >
                                                            {option.Name}
                                                        </option>
                                                    );
                                                })}
                                            </select> */}
                        <select class="form-control" id="dropdownSelect">
                            <option value="">State</option>
                            <option value="1">0</option>
                            <option value="1" selected>1</option>

                        </select>
                    </fieldset>
                </div>
                {/* {priorYear0 ?
                                        <>
                                            <div className="col-12 mt-1">
                                                <fieldset>
                                                    <legend class="middle-legend">When did employees begin working for the Applicant?</legend>
                                                    <input
                                                        type="date"
                                                        className="form-control date-control"
                                                        id=""
                                                        placeholder="When did employees begin working for the Applicant?"
                                                        value={effectiveInputValue}
                                                        onChange={handleEffectiveDateInputChange}
                                                    />
                                                </fieldset>
                                            </div>

                                            <div className="col-12 ">
                                                <fieldset>
                                                    <legend class="middle-legend">Why has coverage not been placed?</legend>
                                                    <select class="form-control" id="dropdownSelect">
                                                        <option value="">Select</option>
                                                        <option value="Non Payment">Non Payment</option>
                                                        <option value="Audit NonCompliance">Audit NonCompliance</option>
                                                        <option value="No Employees  ">No Employees</option>
                                                        <option value="New Business ">New Business </option>
                                                        <option value="Other ">Other </option>
                                                    </select>
                                                </fieldset>
                                            </div>
                                        </>
                                        :
                                        null} */}

                {/* <div className="col-12">
                                        <fieldset>
                                            <legend class="middle-legend">Description </legend>
                                            <textarea
                                                type="text"
                                                class="form-control"
                                                id="exampleTextField"

                                            />
                                        </fieldset>
                                    </div> */}


                <h4>Current Term</h4>

                <div className="col-4 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Effective Date</legend>
                        <input
                            type="date"
                            className="form-control date-control"
                            id=""
                            placeholder="Effective Date"
                            value={effectiveInputValue}
                            onChange={handleEffectiveDateInputChange}
                        />
                    </fieldset>
                </div>

                <div className="col-4 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Expiration Date</legend>
                        <input
                            type="date"
                            className="form-control date-control"
                            id=""
                            placeholder="Expiration Date"
                            value={expirationInputValue}
                            onChange={handleExpirationDateInputChange}
                        />
                    </fieldset>
                </div>
                <div className="col-4 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Carrier </legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Carrier "
                            value="Nationwide"
                        />
                    </fieldset>
                </div>
                <div className="col-4 mt-1">
                    <fieldset>
                        <legend class="middle-legend">Premium </legend>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleTextField"
                            placeholder="Premium "
                            value="$ 1000"
                        />
                    </fieldset>
                </div>
                <div className="col-4 ">
                    <fieldset>
                        <legend class="middle-legend">Claim Count</legend>
                        <select class="form-control" id="dropdownSelect">
                            <option value="0" selected> 0</option>
                            <option value="1"> 1</option>
                            <option value="2"> 2</option>
                            <option value="3"> 3</option>
                            <option value="4"> 4</option>
                            <option value="5"> 5+</option>
                        </select>
                    </fieldset>
                </div>

            </div>
       

        </>
               
    )
}
