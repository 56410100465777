import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Updated import
import './App.css';
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import About from './Components/About';
import './assets/css/custom.css'
import MainPage from "./Pages/MainPage";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<About />} />   
          <Route path="/carrier" element={<MainPage/>} />         
        
        </Routes>
      </Router>
    </div>
  );
}

export default App;
